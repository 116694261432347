import useRequest, { RequestStatus } from "hooks/useRequest";
import { useCallback, useEffect, useRef, useState } from "react";

type DataWithRefresh<DataType> = {
  data: DataType | null;
  lastRefreshedDate: Date | undefined;
  status: RequestStatus;
  errorCode: number | null;
  triggerDataRefresh: () => Promise<void>;
};

/*
  Given a function that fetches some data and an interval at which that data
  should be refreshed, this hook will call that function on load, when requested
  (via outputted function) and if it has been a longer time than the interval
  given without refreshing.

  It returns the datetime that the function was last refreshed, a manual refresh
  function (which also resets the timer and date of last refresh) and the
  current status of the fetcher function (loading / success / error).
 */
const useDataWithRefresh = <DataType>(
  fetcher: () => Promise<DataType>,
  intervalMinutes: number
): DataWithRefresh<DataType> => {
  const { request: updateData, status, errorCode, data } = useRequest(fetcher);
  const [lastRefreshedDate, setLastRefreshedDate] = useState<
    Date | undefined
  >();
  const refreshDataTimer = useRef<NodeJS.Timeout>();

  const triggerDataRefresh = useCallback(async (): Promise<void> => {
    clearTimeout(refreshDataTimer.current);
    const requestDate = new Date();
    // TODO 260419 useRequest swallows errors; don't update date if fetch failed
    await updateData();
    setLastRefreshedDate(requestDate);
    // Refresh failed, don't update the lastRefreshedDate but still set up
    // timer to try again in intervalMinutes
    // Note: if page is not the active tab then timers may take longer to
    // fire, but in this case we do not mind since the user will not be
    // interacting with the page and they can manually refresh when they
    // require up-to-date data
    refreshDataTimer.current = setTimeout(() => {
      triggerDataRefresh();
    }, intervalMinutes * 60_000);
  }, [updateData, intervalMinutes]);

  useEffect(() => {
    triggerDataRefresh();
  }, [triggerDataRefresh]);

  return {
    data,
    lastRefreshedDate,
    status,
    errorCode,
    triggerDataRefresh,
  };
};

// TODO 260419 Add tests for useDataWithRefresh

export default useDataWithRefresh;
