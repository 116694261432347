import { faStar, faTimes } from "@fortawesome/free-solid-svg-icons";
import { lastUpdatedAt } from "assets/updatesData/updates";
import classnames from "classnames";
import Button from "components/components/Button/Button";
import Icon from "components/components/Icon/Icon";
import { IconSize } from "components/components/Icon/IconWrapper";
import React, { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import colours from "styles/colours";
import { parseCookies, setCookie } from "utils/cookieHelper";
import featureFlags from "utils/featureFlags";

import {
  ExitButtonContainer,
  IconContainer,
  StyledToast,
  ToastContent,
} from "./style";

const LAST_UPDATE_DATE_COOKIE = "DateOfLastSeenUpdates";
const LAST_UPDATE_DATE = lastUpdatedAt;

export const dismissNewUpdateToast = (): void => {
  toast.dismiss("1");
  setCookie(LAST_UPDATE_DATE_COOKIE, LAST_UPDATE_DATE);
};

export const NewUpdateToast: React.FC = () => {
  const [dismissed, setDismissed] = useState(false);

  const toastDetails = {
    title: "New pages & updates available",
    message: (
      <Link
        onClick={(): void => {
          setCookie(LAST_UPDATE_DATE_COOKIE, LAST_UPDATE_DATE);
          setDismissed(true);
        }}
        to="/whats-new"
        data-test-id="view-updates-toast-link"
      >
        View updates
      </Link>
    ),
  };

  const dismiss = useCallback((): void => {
    dismissNewUpdateToast();
    setDismissed(true);
  }, []);

  return (
    <StyledToast
      className={classnames({ dismissed })}
      data-test-id="update-toast-container"
    >
      <IconContainer>
        <Icon
          iconName={faStar}
          colour={colours.elexonYellow}
          size={IconSize.mediumSmall}
        />
      </IconContainer>
      <ToastContent>
        <h4 className="title" data-test-id="toast-title">
          {toastDetails.title}
        </h4>
        {toastDetails.message && (
          <p className="toast-message" data-test-id="update-toast-message">
            {toastDetails.message}
          </p>
        )}
      </ToastContent>
      <ExitButtonContainer>
        <Button
          className="plain-inline"
          ariaLabel="Dismiss"
          buttonText=""
          iconName={faTimes}
          onClick={dismiss}
          dataTestId="update-toast-exit-cross"
        />
      </ExitButtonContainer>
    </StyledToast>
  );
};

export const createNewUpdateToast = (): void => {
  if (featureFlags.disableNewUpdateToast) {
    return;
  }

  const lastUpdateDateCookie = parseCookies(document.cookie)[
    LAST_UPDATE_DATE_COOKIE
  ];

  if (lastUpdateDateCookie !== LAST_UPDATE_DATE) {
    toast.custom(<NewUpdateToast />, {
      id: "update-toast",
      duration: Infinity,
    });
  }
};
