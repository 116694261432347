import { Serie } from "@nivo/line";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { RangedChartData } from "models/rangedChartData";
import { transformRangedChartData } from "utils/ChartDataUtils/rangedChartDataTransformer/rangedChartDataTransformer";
import { PointShape } from "utils/ChartDrawUtils/pointShapes";

export const transformDataToBeRelativeToFPNData = (
  chartData: Serie[]
): Serie[] => {
  if (chartData.length === 0) {
    return [];
  }
  const fpnId = "finalPhysicalNotification";

  const fpn = chartData.find((x) => x.id === fpnId)!;

  const nonFPNList = chartData.filter((x) => x.id !== fpnId);

  const nonFPNDataRelativeToFPN = nonFPNList.map((item) => {
    const updatedData = item.data.map((dataEntry) => {
      const matchingFPNDataEntry = fpn.data.find(
        (fpnDataEntry) =>
          (fpnDataEntry.x as Date)?.getTime() ===
            (dataEntry.x as Date)?.getTime() &&
          fpnDataEntry.settlementPeriod === dataEntry.settlementPeriod
      );

      if (
        matchingFPNDataEntry &&
        dataEntry?.y !== undefined &&
        matchingFPNDataEntry?.y !== undefined
      ) {
        return {
          ...dataEntry,
          y: <number>dataEntry.y + <number>matchingFPNDataEntry.y,
        };
      }

      return {
        ...dataEntry,
        y: undefined,
      };
    });
    return { ...item, data: updatedData };
  });

  return [...nonFPNDataRelativeToFPN, fpn];
};

export const convertRawDataToChartData = <T extends string>(
  rawData: RangedChartData<T>[],
  dateFilter: DateFilterModel,
  pointShapeDict: { [key in T]: PointShape },
  colourDict: { [key in T]: string }
): Serie[] => {
  const transformedRangedData = transformRangedChartData(
    rawData,
    dateFilter,
    pointShapeDict,
    colourDict
  );

  return transformDataToBeRelativeToFPNData(transformedRangedData);
};
