import FullWidthSection from "components/components/FullWidthSection/FullWidthSection";
import LastUpdated from "components/components/LastUpdated/LastUpdated";
import PageMeta from "components/components/Meta/PageMeta";
import useDataWithRefresh from "hooks/useDataWithRefresh";
import { RequestStatus } from "hooks/useRequest";
import React, { useCallback } from "react";

type ChartData = {
  randomNumber: number;
};

const Summary: React.FC = () => {
  const fetchDataForRefreshHook = useCallback(async (): Promise<ChartData> => {
    // TODO Replace with real calls to the API
    const waitOneSecond = new Promise((resolve) => setTimeout(resolve, 1000));

    await Promise.allSettled([waitOneSecond]);

    return {
      randomNumber: 4,
    };
  }, []);

  const {
    data: chartData,
    status,
    lastRefreshedDate,
    triggerDataRefresh,
  } = useDataWithRefresh(fetchDataForRefreshHook, 5);

  return (
    <>
      <PageMeta
        title="Summary"
        description="Summary page, only available on dev and test environments"
      />
      <FullWidthSection>
        <h1 data-test-id="summary-heading">Summary</h1>
        <LastUpdated
          triggerDataRefresh={triggerDataRefresh}
          lastRefreshedDate={lastRefreshedDate}
          isLoading={status === RequestStatus.IN_PROGRESS}
        />
        <p>data: {JSON.stringify(chartData)}</p>
      </FullWidthSection>
    </>
  );
};

export default Summary;
